html.no-scroll {
  overflow: hidden; }

.layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%; }
  .layout-content {
    flex-grow: 1;
    background: white url("./img/cta_bkgd.png");
    background-repeat: repeat;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    height: calc(100% - 75px);
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
    .layout-content.no-scroll {
      overflow: hidden !important; }
  .layout-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1; }
  @media (max-width: 768px) {
    .layout-content {
      margin-top: 75px; } }
  @media (min-width: 769px) {
    .layout {
      flex-grow: 1;
      min-height: 100vh; }
      .layout-content {
        position: relative; } }
  @media print {
    .layout-content {
      background: none; } }
