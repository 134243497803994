.search-box {
  position: relative; }
  .search-box.background {
    color: white; }
    .search-box.background .search-form-filter label {
      color: #e2e0e0; }
  .search-box .a-spot-content:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 0; }

@keyframes flash-search-box {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }
  .search-box.flash .a-spot-content:before {
    animation-name: flash-search-box;
    animation-duration: .5s; }
  .search-box-content {
    position: relative;
    align-self: stretch;
    padding: 1vw 0; }
  .search-box-a-spot {
    align-items: center; }
  .search-box-horizontal {
    display: flex;
    justify-content: center; }
  .search-box-title {
    text-align: center;
    padding-left: 36px;
    padding-right: 36px; }
  .search-box .search-form.vertical {
    padding-left: 0;
    padding-right: 0; }
  @media (max-width: 768px) {
    .search-box-a-spot .search-form.vertical .search-form-filter {
      padding-left: 0;
      padding-right: 0; } }
