.main-footer {
  flex-shrink: 0;
  position: relative;
  background-color: #414142;
  color: white;
  display: flex;
  align-items: baseline;
  padding: 12px;
  overflow: hidden;
  box-shadow: 0 0px 6px 0px rgba(38, 38, 38, 0.5); }
  .main-footer-links {
    flex-grow: 1;
    padding: 0 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 1.2rem;
    align-items: center; }
    .main-footer-links ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex; }
      .main-footer-links ul > li {
        flex-shrink: 0;
        margin: 0;
        padding: 0 12px; }
    .main-footer-links .icon {
      font-size: 1.2rem; }
    .main-footer-links .social-buttons {
      margin-left: auto; }
  .main-footer a {
    color: #bdb8b8 !important;
    text-transform: lowercase; }
  .main-footer-copyright {
    flex-shrink: 0;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    color: #e6e6e6;
    margin: 0 12px; }
  @media (max-width: 768px) {
    .main-footer {
      display: none; } }
  @media print {
    .main-footer {
      display: none !important; } }
