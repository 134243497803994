.language-selector {
  margin-right: 24px;
  font-size: 2rem; }
  .language-selector label {
    font-family: "Source Sans Pro", sans-serif;
    text-transform: none;
    font-weight: 400;
    font-size: 1.6rem;
    letter-spacing: .03em;
    line-height: 2.6rem;
    margin-top: 0.43333rem;
    margin-bottom: 0.86667rem;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: .6rem;
    display: block;
    margin-bottom: 0; }
  .language-selector-option {
    margin: 0;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 12px;
    position: relative;
    display: inline-flex;
    align-items: center; }
    .language-selector-option.current:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 35px;
      height: 35px;
      transform: translate(-50%, -50%);
      border: 2px solid #e2e0e0;
      border-radius: 50%; }
