.main-header {
  box-shadow: 0 0px 6px 0px rgba(38, 38, 38, 0.5);
  position: relative;
  z-index: 1; }
  .main-header-inner {
    position: relative;
    z-index: 2;
    flex-grow: 0;
    flex-basis: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: grey;
    background-color: white; }
  .main-header-top {
    display: flex;
    align-items: stretch;
    min-height: 75px;
    padding: 0 12px; }
  .main-header .logo {
    align-self: center; }
    .main-header .logo > img {
      width: 30vw;
      max-width: 150px;
      min-width: 110px; }
  .main-header-search {
    flex-grow: 1;
    min-height: 0;
    height: auto;
    padding-top: 0;
    padding-bottom: 0; }
    .main-header-search .search-box-filter label {
      flex-direction: row; }
    .main-header-search .search-box-title {
      display: none; }
    .main-header-search .search-box-content {
      padding: 0; }
  .main-header .region-selector {
    margin-left: auto; }
  .main-header-menu-button {
    background: none !important;
    border: none !important;
    appearance: none !important;
    margin-left: auto;
    align-self: center;
    padding: 12px; }
  .main-header-mobile-menu {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    z-index: 1;
    pointer-events: none;
    transition: height 0s .5s; }
    .main-header-mobile-menu.open {
      transition: none;
      height: 100vh; }
      .main-header-mobile-menu.open .main-header-mobile-menu-inner {
        transform: translateX(0); }
  .main-header-mobile-menu-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 75px;
    padding-bottom: 110px;
    pointer-events: auto;
    transform: translateX(100%);
    transition: transform .5s;
    background-color: #676769;
    display: flex;
    flex-direction: column; }
    .main-header-mobile-menu-inner .main-header-nav {
      flex-grow: 1;
      flex-shrink: 0;
      display: flex;
      background-color: #414142; }
      .main-header-mobile-menu-inner .main-header-nav > ul {
        margin-top: 12px;
        margin-bottom: 12px; }
      .main-header-mobile-menu-inner .main-header-nav a {
        padding-top: 8px;
        padding-bottom: 8px; }
    .main-header-mobile-menu-inner .main-header-nav-footer {
      background-color: #676769;
      text-align: right;
      font-size: 1.8rem;
      text-transform: none; }
      .main-header-mobile-menu-inner .main-header-nav-footer > ul {
        margin-top: 24px; }
      .main-header-mobile-menu-inner .main-header-nav-footer a {
        justify-content: flex-end; }
  .main-header-region {
    margin-left: 18px;
    margin-right: 12px;
    font-size: 1.3em !important; }
  .main-header-copyright {
    text-align: center;
    color: #bfbfbf;
    margin-top: 48px; }
  .main-header-social {
    flex-shrink: 0;
    font-size: 2rem;
    justify-content: space-between;
    margin-top: 24px !important;
    margin-left: 24px;
    margin-right: 24px;
    flex-wrap: wrap; }
    .main-header-social > li {
      padding: 6px; }
  @media (max-width: 768px) {
    .main-header-inner {
      position: fixed;
      width: 100%; }
    .main-header-search {
      display: none; }
    .main-header-top {
      box-shadow: 0 0px 6px 0px rgba(38, 38, 38, 0.5); } }
  @media print {
    .main-header {
      display: none !important; } }
