.command-bar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: stretch;
  justify-content: center; }
  .command-bar ul > li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px; }
    .command-bar ul > li:first-child {
      padding-left: 0; }
    .command-bar ul > li:last-child {
      padding-right: 0; }
    .command-bar ul > li > * {
      margin: 0; }
    .command-bar ul > li[role=separator] {
      flex-grow: 1; }

.command-bar.vertical:not(.mobile) ul {
  flex-direction: column; }
  .command-bar.vertical:not(.mobile) ul > li {
    padding: 6px; }
    .command-bar.vertical:not(.mobile) ul > li:first-child {
      padding-top: 0; }
    .command-bar.vertical:not(.mobile) ul > li:last-child {
      padding-bottom: 0; }

.command-bar.mobile {
  background-color: #e2e0e0;
  padding: 8px 24px; }
  .command-bar.mobile ul {
    width: 100%;
    align-items: stretch; }
    .command-bar.mobile ul > li {
      flex-basis: 0;
      flex-shrink: 1;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center; }
      .command-bar.mobile ul > li > * {
        flex-basis: 100%; }
