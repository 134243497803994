.primary-button {
  appearance: none !important;
  background: none !important;
  border: none !important;
  display: inline-block;
  position: relative;
  margin: 6px;
  background: none;
  text-transform: uppercase;
  padding: 12px 35px;
  font-size: 14px;
  font-style: normal;
  letter-spacing: .1em;
  border-radius: 5px;
  text-decoration: none !important;
  text-align: center;
  cursor: pointer; }
  .primary-button:disabled, .primary-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5; }
  .primary-button.first {
    margin-left: 0; }
  .primary-button.last {
    margin-right: 0; }
  .primary-button.medium {
    padding-top: 8px;
    padding-bottom: 8px; }
  .primary-button.small {
    padding: 5px 18px;
    font-size: 12px; }
  .primary-button.dark .button-caption {
    color: white !important; }
  .primary-button.light .button-caption {
    color: #262626 !important; }
  .primary-button.light:hover .button-caption,
  .primary-button.light:hover .button-caption .icon {
    color: white !important; }
  .primary-button .button-caption {
    position: relative;
    color: white;
    text-decoration: none !important;
    font-weight: 600 !important;
    font-family: "Source Sans Pro", sans-serif; }
    .primary-button .button-caption,
    .primary-button .button-caption .icon {
      transition: color .1s; }
    .primary-button .button-caption .left-icon,
    .primary-button .button-caption .right-icon {
      display: inline-block; }
    .primary-button .button-caption .left-icon {
      transform: translateX(-50%); }
    .primary-button .button-caption .right-icon {
      transform: translateX(50%); }
    .primary-button .button-caption.has-left-icon {
      margin-right: -.5em; }
    .primary-button .button-caption.has-right-icon {
      margin-left: -.5em; }
    .primary-button .button-caption.has-left-icon.has-right-icon {
      margin-left: 0;
      margin-right: 0; }
  .primary-button .button-background {
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    overflow: hidden;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    background-color: white; }
    .primary-button .button-background-inner {
      position: absolute;
      transform: translate(-50%, -50%) rotate(45deg);
      transition: all .35s;
      transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59); }
  .primary-button .button-border {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    border-radius: 5px;
    fill: none; }
    .primary-button .button-border rect {
      stroke: white;
      stroke-width: 1px;
      stroke-linecap: round;
      opacity: .7;
      fill: rgba(218, 38, 49, 0);
      transition: fill .05s, stroke 1s; }
      .primary-button .button-border rect.hover-border {
        stroke: transparent;
        stroke-width: 1px;
        fill: rgba(38, 38, 38, 0.25);
        opacity: .9; }
  .primary-button:active {
    z-index: 1; }
    .primary-button:active:before {
      background-color: black; }
    .primary-button:active .button-caption,
    .primary-button:active .button-caption .icon {
      color: white !important; }
    .primary-button:active .button-background {
      transition: none; }
  .primary-button:hover .button-background {
    transition: background-color .1s .2s; }
    .primary-button:hover .button-background-inner {
      left: 50% !important;
      top: 50% !important; }
  .primary-button .button-background {
    border-color: #da2631; }
    .primary-button .button-background-inner {
      background-color: #da2631; }
  .primary-button:hover .button-border rect.hover-border {
    stroke: #da2631; }
  .primary-button:hover .button-background {
    background-color: #da2631; }
  .primary-button:active .button-border rect {
    fill: #da2631; }
  .primary-button:active .button-background {
    border-color: #a61c25;
    background-color: #a61c25; }
    .primary-button:active .button-background:after {
      background-color: #a61c25; }
  .primary-button.facebook .button-background {
    border-color: #4058A9; }
    .primary-button.facebook .button-background-inner {
      background-color: #4058A9; }
  .primary-button.facebook:hover .button-border rect.hover-border {
    stroke: #4058A9; }
  .primary-button.facebook:hover .button-background {
    background-color: #4058A9; }
  .primary-button.facebook:active .button-border rect {
    fill: #4058A9; }
  .primary-button.facebook:active .button-background {
    border-color: #2f417d;
    background-color: #2f417d; }
    .primary-button.facebook:active .button-background:after {
      background-color: #2f417d; }
