input.password::-ms-clear, input.password::-ms-reveal {
  display: none; }

.password-field.show-controls .password {
  padding-right: 45px; }

.password-field .password-reveal {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background: none !important;
  border: 0 !important;
  font-size: 1.4em;
  padding: 0 12px; }

.password-field .form-group-children {
  display: flex; }

.password-field .password-strength {
  flex-grow: 1;
  flex-shrink: 0; }
  .password-field .password-strength .password-strength-bar {
    height: 3px;
    width: 100%;
    left: 0;
    position: absolute;
    top: -3px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
    background-color: rgba(65, 65, 66, 0.2); }
    .password-field .password-strength .password-strength-bar .password-strength-fill {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 0;
      background-color: white;
      transition: all .5s; }
  .password-field .password-strength .btn-link {
    padding-left: 0;
    padding-right: 0;
    border-width: 0;
    text-decoration: underline; }
  .password-field .password-strength .password-strength-label {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    padding: 6px 0;
    font-size: 12px;
    text-transform: uppercase;
    text-align: right;
    margin-left: 16px;
    transition: color .5s; }
