.main-nav-separator {
  height: 12px; }

.main-nav-link-dropdown .main-nav-separator {
  position: relative; }
  .main-nav-link-dropdown .main-nav-separator:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    width: 100%;
    height: 1px;
    background-color: rgba(226, 224, 224, 0.1); }
