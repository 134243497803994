.text-field .input-group {
  display: flex;
  width: 100%;
  min-height: 50px; }
  .text-field .input-group > .form-control:first-child {
    padding-right: 6px;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .text-field .input-group > .form-control:last-child {
    padding-left: 6px;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.text-field .input-group-addon {
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px; }
  .text-field .input-group-addon:first-child {
    padding-right: 6px;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .text-field .input-group-addon:last-child {
    padding-left: 6px;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
