.loading-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1; }
  .loading-overlay-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 24px; }
  .loading-overlay.scrim {
    background: radial-gradient(rgba(255, 255, 255, 0.95) 20%, rgba(255, 255, 255, 0.5)); }
