.main-nav {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #414142;
  padding: 0 12px; }
  .main-nav ul {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap; }
    .main-nav ul > li {
      margin: 0;
      display: flex;
      flex-direction: column; }
  .main-nav > ul > li {
    flex-shrink: 0;
    margin-left: 4px;
    margin-right: 4px; }
  .main-nav > ul > li[role=separator] {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0; }
  .main-nav a {
    flex-grow: 1;
    color: #e2e0e0 !important; }
  .main-nav.mobile {
    font-size: 2rem; }
    .main-nav.mobile ul {
      flex-direction: column; }
      .main-nav.mobile ul > li {
        margin-top: 6px;
        margin-bottom: 6px; }
        .main-nav.mobile ul > li.has-dropdown {
          background-color: #4e4e4f; }
    .main-nav.mobile > ul > li[role=separator] {
      flex-basis: 12px; }
  .main-nav:not(.mobile) > ul > li[role=separator] + li {
    margin-left: auto; }
  @media (max-width: 768px) {
    .main-nav:not(.mobile) {
      display: none; } }
