.a-spot {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 24px;
  height: 45vh;
  min-height: 200px; }
  .a-spot.no-image h2 {
    color: #414142; }
  .a-spot:not(.no-image) {
    color: white; }
    .a-spot:not(.no-image) h2 {
      color: #e2e0e0; }
  .a-spot-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }
    .a-spot-background:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(65, 65, 66, 0.7); }
  .a-spot-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-top: 48px;
    padding-bottom: 36px;
    padding-left: 12px;
    padding-right: 12px;
    text-align: center;
    max-width: 750px; }
  @media (max-width: 768px) {
    .a-spot-content {
      text-align: left;
      align-items: flex-start; } }
