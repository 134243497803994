.main-nav-link {
  position: relative; }
  .main-nav-link > a {
    cursor: pointer;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-decoration: none !important; }
    .main-nav-link > a:hover {
      background-color: rgba(218, 38, 49, 0.8);
      color: white !important; }
  .main-nav-link.highlight {
    font-weight: 700;
    text-transform: none;
    font-size: 1.05em; }
    .main-nav-link.highlight > a {
      color: #414142 !important;
      background-color: white;
      margin-top: 12px;
      margin-bottom: 12px;
      padding-top: 6px;
      padding-bottom: 6px; }
      .main-nav-link.highlight > a:hover {
        background-color: rgba(218, 38, 49, 0.8);
        color: white !important;
        box-shadow: 0 0 0 white; }
    .main-nav-link.highlight.secondary > a {
      font-weight: 400;
      position: relative; }
      .main-nav-link.highlight.secondary > a:before {
        content: '';
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #808082; }
      .main-nav-link.highlight.secondary > a:not(:hover) {
        background-color: #4e4e4f;
        color: white !important; }
        .main-nav-link.highlight.secondary > a:not(:hover):before {
          display: block; }
  .main-nav-link.has-dropdown > a > .icon {
    margin-left: 6px;
    display: inline-block;
    font-size: 1.2em;
    color: #bdb8b8;
    transform: none;
    transition: transform .5s; }
    .main-nav-link.has-dropdown > a > .icon:before {
      margin-right: 0; }
  .main-nav-link.open.has-dropdown {
    background-color: #676769; }
  .main-nav-link.open ul.main-nav-link-dropdown {
    display: block;
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    transform: none;
    transition: transform .5s; }
  .main-nav-link ul.main-nav-link-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    z-index: 10;
    width: auto;
    background-color: #676769;
    box-shadow: 0 0 2px 2px rgba(65, 65, 66, 0.5);
    padding: 8px 0;
    display: block;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-0.5rem);
    transition: none; }
  .main-nav-link.mobile > a {
    justify-content: space-between;
    white-space: normal; }
    .main-nav-link.mobile > a:after {
      font-size: 1.3rem; }
    .main-nav-link.mobile > a:hover {
      background: none; }
  .main-nav-link.mobile.has-dropdown.open {
    margin-bottom: 18px; }
    .main-nav-link.mobile.has-dropdown.open > a {
      background-color: #808082; }
      .main-nav-link.mobile.has-dropdown.open > a > .icon {
        transform: scaleY(-1); }
    .main-nav-link.mobile.has-dropdown.open ul.main-nav-link-dropdown {
      display: block; }
  .main-nav-link.mobile ul.main-nav-link-dropdown {
    position: static;
    box-shadow: none;
    display: none; }
    .main-nav-link.mobile ul.main-nav-link-dropdown a {
      text-transform: none;
      padding-left: 48px; }
