@font-face {
  font-family: 'carsandjobs-icons';
  src: url('../font/carsandjobs-icons.eot?18461163');
  src: url('../font/carsandjobs-icons.eot?18461163#iefix') format('embedded-opentype'),
       url('../font/carsandjobs-icons.woff2?18461163') format('woff2'),
       url('../font/carsandjobs-icons.woff?18461163') format('woff'),
       url('../font/carsandjobs-icons.ttf?18461163') format('truetype'),
       url('../font/carsandjobs-icons.svg?18461163#carsandjobs-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'carsandjobs-icons';
    src: url('../font/carsandjobs-icons.svg?18461163#carsandjobs-icons') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "carsandjobs-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-cancel:before { content: '\e800'; } /* '' */
.icon-home:before { content: '\e801'; } /* '' */
.icon-star:before { content: '\e802'; } /* '' */
.icon-mail:before { content: '\e803'; } /* '' */
.icon-star-empty:before { content: '\e804'; } /* '' */
.icon-search:before { content: '\e805'; } /* '' */
.icon-check:before { content: '\e806'; } /* '' */
.icon-eye-off:before { content: '\e807'; } /* '' */
.icon-eye:before { content: '\e808'; } /* '' */
.icon-delete:before { content: '\e809'; } /* '' */
.icon-bookmark:before { content: '\e80a'; } /* '' */
.icon-user:before { content: '\e80b'; } /* '' */
.icon-video:before { content: '\e80c'; } /* '' */
.icon-bold:before { content: '\e80d'; } /* '' */
.icon-italic:before { content: '\e80e'; } /* '' */
.icon-link:before { content: '\e80f'; } /* '' */
.icon-picture:before { content: '\e810'; } /* '' */
.icon-edit:before { content: '\e811'; } /* '' */
.icon-briefcase:before { content: '\e812'; } /* '' */
.icon-megaphone:before { content: '\e813'; } /* '' */
.icon-refresh:before { content: '\e814'; } /* '' */
.icon-lock:before { content: '\e815'; } /* '' */
.icon-ellipsis:before { content: '\e816'; } /* '' */
.icon-export:before { content: '\e817'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-filter:before { content: '\f0b0'; } /* '' */
.icon-list-ul:before { content: '\f0ca'; } /* '' */
.icon-list-ol:before { content: '\f0cb'; } /* '' */
.icon-strikethrough:before { content: '\f0cc'; } /* '' */
.icon-underline:before { content: '\f0cd'; } /* '' */
.icon-gplus:before { content: '\f0d5'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-quote-right:before { content: '\f10e'; } /* '' */
.icon-code:before { content: '\f121'; } /* '' */
.icon-doc-text-inv:before { content: '\f15c'; } /* '' */
.icon-youtube:before { content: '\f16a'; } /* '' */
.icon-education:before { content: '\f19d'; } /* '' */
.icon-file-word:before { content: '\f1c2'; } /* '' */
.icon-heading:before { content: '\f1dc'; } /* '' */