.search-form {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  width: 700px;
  max-width: 100%; }
  .search-form-filter {
    flex-basis: 0;
    flex-grow: .5;
    padding: 12px; }
    .search-form-filter label {
      font-size: 24px;
      text-transform: lowercase; }
    .search-form-filter .field-inner {
      min-width: 0 !important; }
    .search-form-filter .custom-dropdown {
      min-width: 36px; }
  .search-form.horizontal {
    width: 900px;
    max-width: 65vw; }
  .search-form.vertical .search-form-button {
    align-self: stretch;
    padding-top: 44px; }
  .search-form-button {
    font-size: 24px; }
  @media (max-width: 1024px) {
    .search-form.horizontal .search-form-button-inner {
      padding-left: 18px;
      padding-right: 18px; } }
  @media (max-width: 768px) {
    .search-form {
      width: 100%;
      flex-direction: column;
      align-items: stretch; }
      .search-form-filter {
        flex-basis: auto; }
      .search-form-button {
        padding-top: 0 !important; }
      .search-form .field {
        margin-bottom: 0; }
      .search-form-button {
        margin: 12px 0;
        text-align: center; } }
