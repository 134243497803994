.object-fit {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .object-fit .object-fit-outer {
    position: absolute; }
  .object-fit.center .object-fit-outer {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .object-fit.bottom .object-fit-outer {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }
  .object-fit.top .object-fit-outer {
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
  .object-fit.left .object-fit-outer {
    left: 0;
    top: 50%;
    transform: translateY(-50%); }
  .object-fit.right .object-fit-outer {
    right: 0;
    top: 50%;
    transform: translateY(-50%); }
  .object-fit .object-fit-inner {
    position: relative;
    width: 100%;
    height: 0; }

.object-fit-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .object-fit-content > * {
    width: 100% !important;
    height: 100% !important; }
  .object-fit-content.contain > * {
    object-fit: contain; }
  .object-fit-content.cover > * {
    object-fit: cover; }
  .object-fit-content.center > * {
    object-position: center; }
  .object-fit-content.left > * {
    object-position: left; }
  .object-fit-content.top > * {
    object-position: top; }
  .object-fit-content.right > * {
    object-position: right; }
  .object-fit-content.bottom > * {
    object-position: bottom; }
