.responsive-modal-frame {
  display: flex;
  flex-direction: column;
  align-items: stretch; }
  .responsive-modal-frame-header {
    display: flex;
    align-items: center;
    min-width: 230px;
    margin-bottom: 24px; }
    .responsive-modal-frame-header h1, .responsive-modal-frame-header h2, .responsive-modal-frame-header h3, .responsive-modal-frame-header h4, .responsive-modal-frame-header h5, .responsive-modal-frame-header h6 {
      margin-bottom: 0; }
    .responsive-modal-frame-header h1 {
      font-size: 2.5em; }
    .responsive-modal-frame-header h1, .responsive-modal-frame-header h2, .responsive-modal-frame-header h3, .responsive-modal-frame-header h4, .responsive-modal-frame-header h5, .responsive-modal-frame-header h6 {
      text-transform: none; }
  .responsive-modal-frame-title {
    margin-right: 24px; }
  .responsive-modal-frame .header-strip {
    padding: 0 24px; }
  .responsive-modal-frame-content {
    overflow: auto;
    display: flex; }
  .responsive-modal-frame-close {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: auto; }
  .responsive-modal-frame:not(.mobile) .responsive-modal-frame-content {
    padding: 0;
    max-width: 450px; }
  @media (max-width: 640px) {
    .responsive-modal-frame-header {
      min-width: 0; } }

.modal-content .responsive-modal-frame {
  margin-top: -4px; }
