.menu-icon {
  width: 24px;
  height: 24px;
  position: relative; }
  .menu-icon .menu-icon-inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .menu-icon .menu-icon-line {
    position: absolute;
    width: 24px;
    height: 3px;
    background-color: #262626;
    left: 50%;
    top: 50%;
    transition: transform .4s; }
    .menu-icon .menu-icon-line.line-1 {
      transform: translate(-50%, -9px); }
    .menu-icon .menu-icon-line.line-2 {
      transform: translate(-50%, 0); }
    .menu-icon .menu-icon-line.line-3 {
      transform: translate(-50%, 9px); }
  .menu-icon.expanded .menu-icon-line.line-1, .menu-icon.expanded .menu-icon-line.line-2 {
    transform: translate(-50%, 0) rotate(-45deg); }
  .menu-icon.expanded .menu-icon-line.line-3 {
    transform: translate(-50%, 0) rotate(45deg); }
