.social-buttons {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex; }
  .social-buttons > li {
    margin: 0;
    padding: 12px; }
  .social-buttons a {
    display: flex;
    width: 2.5em;
    height: 2.5em;
    align-items: stretch;
    justify-content: stretch;
    opacity: .7; }
    .social-buttons a:hover, .social-buttons a:active {
      opacity: 1;
      text-decoration: none; }
  .social-buttons .icon {
    font-size: 1.3em !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 50%;
    color: white; }
  .social-buttons .icon-gplus:before {
    width: 1.5em; }
  .social-buttons .icon-gplus {
    background-color: #DB4437; }
  .social-buttons .icon-twitter {
    background-color: #1DA1F2; }
  .social-buttons .icon-linkedin {
    background-color: #0077B5; }
  .social-buttons .icon-facebook {
    background-color: #3B5998; }
  .social-buttons .icon-youtube {
    background-color: #FF0000; }
