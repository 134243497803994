.primary-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer; }
  .primary-link:disabled, .primary-link[disabled] {
    cursor: not-allowed;
    opacity: 0.5; }
  .primary-link.small {
    font-family: "Source Sans Pro", sans-serif;
    text-decoration: none;
    color: #da2631 !important;
    font-size: 1em;
    padding-top: 8px;
    padding-bottom: 8px; }
    .primary-link.small:hover, .primary-link.small:active {
      text-decoration: underline; }
    .primary-link.small:active {
      color: rgba(218, 38, 49, 0.7) !important; }
  .primary-link.large {
    font-family: "Source Sans Pro", sans-serif;
    text-decoration: none;
    color: #da2631 !important;
    font-size: 1.2em; }
    .primary-link.large:hover, .primary-link.large:active {
      text-decoration: underline; }
    .primary-link.large:active {
      color: rgba(218, 38, 49, 0.7) !important; }
  .primary-link.x-large {
    font-family: "Source Sans Pro", sans-serif;
    text-decoration: none;
    color: #da2631 !important;
    font-size: 2rem; }
    .primary-link.x-large:hover, .primary-link.x-large:active {
      text-decoration: underline; }
    .primary-link.x-large:active {
      color: rgba(218, 38, 49, 0.7) !important; }
  .primary-link > .icon:first-child {
    margin-right: 4px; }
    .primary-link > .icon:first-child:before {
      margin-left: 0; }
  .primary-link > .icon:last-child {
    margin-left: 4px; }
    .primary-link > .icon:last-child:before {
      margin-right: 0; }
  .primary-link.clip {
    overflow: hidden;
    white-space: nowrap; }
    .primary-link.clip > .primary-link-children {
      text-overflow: ellipsis;
      overflow: hidden; }
