h1, h2, h3, h4, h5, h6 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  line-height: 1.15;
  margin-bottom: .7em; }

h1 {
  font-family: "Open Sans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  text-transform: none; }

h2 {
  font-size: 1.5em;
  text-transform: uppercase; }

h2, h3, h4, h5, h6 {
  color: #164365; }

h3 {
  font-size: 1.17em; }

h4 {
  font-size: 1.05em; }

h5, h6 {
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  line-height: 1.5; }

h5 {
  font-size: .9em; }

h6 {
  font-size: .8em; }

html {
  overflow: hidden; }
  @media (min-width: 769px) {
    html {
      overflow: visible;
      min-height: 100vh; } }

body {
  width: 100%;
  background-color: #414142; }
  @media print {
    body {
      background: none; } }

#root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  min-height: 100%;
  max-width: 1920px; }

body, #root {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0 auto; }
  @media (min-width: 769px) {
    body, #root {
      position: static;
      height: auto;
      min-height: 100vh; } }

html {
  font-size: 10px;
  /* @media (max-width: $screen-md-max) {
			font-size: 9px;
		} */ }

body {
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  color: #262626; }

p {
  margin-bottom: 1em; }

b, strong {
  font-weight: 600; }

a {
  text-decoration: none;
  color: #da2631 !important; }
  a:hover, a:active {
    text-decoration: underline; }
  a:active {
    color: rgba(218, 38, 49, 0.7) !important; }

blockquote {
  background-color: #e2e0e0;
  padding: 12px 24px;
  font-style: italic; }

code {
  font-family: inherit;
  font-style: italic; }

pre {
  overflow: visible;
  white-space: normal; }

table {
  border: 1px solid #e2e0e0;
  background-color: white; }

tr {
  background-color: white; }

td, th {
  border: 1px solid #e2e0e0;
  padding: 6px 12px; }
  td.min-width, th.min-width {
    width: 1%; }
  td.align-center, th.align-center {
    text-align: center; }
  td > .primary-link, th > .primary-link {
    margin-top: -6px;
    margin-bottom: -6px; }

th {
  color: white;
  font-family: "Source Sans Pro", sans-serif;
  background-color: #164365;
  font-weight: 600; }

thead > tr {
  color: white;
  font-family: "Source Sans Pro", sans-serif; }

tbody > tr:nth-child(even) > td {
  background-color: rgba(226, 224, 224, 0.5); }

tbody > tr:hover > td, tbody > tr:active > td {
  background-color: rgba(22, 67, 101, 0.3); }

tfoot > tr:first-child > td {
  position: relative;
  font-weight: 600; }
  tfoot > tr:first-child > td:before {
    content: '';
    display: block;
    top: 0;
    left: -1px;
    right: -1px;
    height: 1px;
    position: absolute;
    background-color: #414142; }

hr {
  border: 1px solid #e2e0e0;
  margin: 24px 0; }

.icon-angle-left:before {
  margin-left: .1em; }

.icon-angle-right:before {
  margin-right: .1em; }

@media (max-width: 640px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 641px) and (max-width: 768px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 769px) and (max-width: 1024px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1025px) {
  .hidden-lg {
    display: none !important; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }
