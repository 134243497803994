.radial-button {
  display: inline-block;
  border: 1px solid #da2631;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  position: relative;
  color: #da2631;
  padding: 0;
  margin: 8px;
  cursor: pointer;
  font-size: 2rem;
  text-decoration: none !important; }
  .radial-button.dark {
    background-color: transparent; }
  .radial-button.large {
    width: 43px;
    height: 43px;
    min-width: 43px;
    min-height: 43px; }
  .radial-button.tiny {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    font-size: 1.4rem; }
  .radial-button.first {
    margin-left: 0; }
  .radial-button.last {
    margin-right: 0; }
  .radial-button:disabled {
    opacity: .5;
    cursor: not-allowed; }
  .radial-button:active, .radial-button:hover {
    box-shadow: none; }
  .radial-button.expanded {
    border-color: #414142; }
  .radial-button:hover, .radial-button.hover {
    background-color: #da2631;
    border-color: #da2631;
    color: white !important; }
  .radial-button > .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }
