.modal-portal {
  position: absolute;
  z-index: 100; }

.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(65, 65, 66, 0.5);
  animation-name: overlay-appear;
  animation-duration: .25s; }

@keyframes overlay-appear {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes overlay-disappear {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
  .modal-overlay.closing {
    animation-name: overlay-disappear;
    animation-duration: .25s;
    animation-fill-mode: forwards; }

.modal-content {
  padding: 24px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px rgba(65, 65, 66, 0.5);
  max-width: 90vw;
  max-height: 90vh;
  overflow: auto;
  animation-name: modal-pop-in;
  animation-duration: .25s; }
  .modal-content.dark {
    background-color: #262626; }

@keyframes modal-pop-in {
  0% {
    transform: scale(0.9);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes modal-pop-out {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0.9);
    opacity: 0; } }
  .modal-content.closing {
    animation-name: modal-pop-out;
    animation-duration: .25s;
    animation-fill-mode: forwards; }
