.header-strip-content-large {
  margin: 1em 24px;
  padding: 0 12px; }
  .header-strip-content-large h1 {
    text-transform: none;
    font-size: 2.6rem; }
  .header-strip-content-large .header-strip-content-back:first-child {
    margin-left: -12px !important; }
  @media (max-width: 640px) {
    .header-strip-content-large {
      padding: 0; }
      .header-strip-content-large .header-strip-content-back:first-child {
        margin-left: 0 !important; } }
