.empty-state {
  font-style: italic;
  text-align: center;
  width: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 5rem;
  color: #414142;
  font-size: 2rem; }
  .empty-state.inline {
    min-height: 0;
    padding: 12px;
    font-size: 1.2em; }
  .empty-state strong {
    font-style: normal;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    margin: .1em;
    color: #262626; }
