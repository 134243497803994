.header-strip-content {
  display: flex;
  align-items: center;
  width: 100%; }
  .header-strip-content-back {
    margin-left: 0 !important;
    margin-right: 24px !important;
    border: none; }
  .header-strip-content h1 {
    font-size: 1.8rem;
    font-weight: 600;
    margin: 0; }
  @media print {
    .header-strip-content-back {
      display: none; } }
