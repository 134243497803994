@import url(~react-select/dist/react-select.css);
.dropdown-field .field-inner {
  width: auto !important; }

.dropdown-field .custom-dropdown {
  display: inline-block;
  min-width: 220px;
  width: 100%; }
  .dropdown-field .custom-dropdown .custom-dropdown-inner {
    position: relative;
    font-size: 18px;
    color: #262626; }
  .dropdown-field .custom-dropdown .Select.is-focused, .dropdown-field .custom-dropdown .Select.is-pseudo-focused {
    border: none;
    outline-offset: 2px !important;
    box-shadow: none !important; }
    .dropdown-field .custom-dropdown .Select.is-focused > .Select-control, .dropdown-field .custom-dropdown .Select.is-pseudo-focused > .Select-control {
      border: 1px solid #262626;
      box-shadow: none; }
  .dropdown-field .custom-dropdown .Select * {
    outline: none !important; }
  .dropdown-field .custom-dropdown .Select .Select-arrow-zone .icon {
    font-size: 24px;
    display: inline-block; }
    .dropdown-field .custom-dropdown .Select .Select-arrow-zone .icon:before {
      transition: transform .5s, color .5s; }
  .dropdown-field .custom-dropdown .Select .Select-clear-zone {
    color: #bdb8b8; }
  .dropdown-field .custom-dropdown .Select.is-open .Select-arrow-zone .icon:before {
    transform: scaleY(-1); }
  .dropdown-field .custom-dropdown .Select.is-disabled > .Select-control {
    background-color: #e2e0e0;
    cursor: not-allowed !important; }
    .dropdown-field .custom-dropdown .Select.is-disabled > .Select-control, .dropdown-field .custom-dropdown .Select.is-disabled > .Select-control.Select-value {
      color: #414142 !important; }
    .dropdown-field .custom-dropdown .Select.is-disabled > .Select-control .Select-arrow-zone {
      opacity: 1; }
  .dropdown-field .custom-dropdown .Select.is-clearable > .Select-control .Select-value {
    padding-right: 68px; }
  .dropdown-field .custom-dropdown .Select.is-searchable .Select-control {
    cursor: text; }
  .dropdown-field .custom-dropdown .Select-control {
    position: relative;
    border: 1px solid #e2e0e0;
    color: #262626;
    cursor: pointer; }
    .dropdown-field .custom-dropdown .Select-control:hover {
      box-shadow: none; }
  .dropdown-field .custom-dropdown .Select-input {
    display: inline-flex !important;
    align-items: center;
    position: relative;
    z-index: 1;
    background: none !important;
    padding-left: 16px; }
    .dropdown-field .custom-dropdown .Select-input > input {
      outline: none !important;
      padding-top: 0;
      padding-bottom: 0; }
  .dropdown-field .custom-dropdown .Select-placeholder,
  .dropdown-field .custom-dropdown .Select > .Select-control .Select-value {
    padding-left: 16px;
    padding-right: 40px;
    color: #262626; }
  .dropdown-field .custom-dropdown .Select-placeholder {
    color: rgba(65, 65, 66, 0.5); }
  .dropdown-field .custom-dropdown .Select-menu-outer {
    border-radius: 0;
    z-index: 5; }
  .dropdown-field .custom-dropdown .Select-option {
    display: flex;
    align-items: center;
    padding: 0 16px;
    text-transform: none !important;
    color: #262626;
    border-bottom: 1px solid #e2e0e0; }
    .dropdown-field .custom-dropdown .Select-option:last-child {
      border-radius: 0;
      border-bottom: none; }
    .dropdown-field .custom-dropdown .Select-option.is-selected {
      background-color: #da2631;
      color: white; }
    .dropdown-field .custom-dropdown .Select-option.is-focused:not(.is-selected) {
      background-color: rgba(218, 38, 49, 0.7);
      color: white; }
  .dropdown-field .custom-dropdown .Select-noresults {
    text-transform: uppercase;
    font-size: .75em; }
  .dropdown-field .custom-dropdown .Select-arrow-zone {
    color: #da2631; }
  .dropdown-field .custom-dropdown.no-arrow .Select-placeholder,
  .dropdown-field .custom-dropdown.no-arrow .Select > .Select-control .Select-value {
    padding-right: 16px; }
  .dropdown-field .custom-dropdown.no-arrow .Select.is-clearable > .Select-control .Select-value {
    padding-right: 32px; }
  .dropdown-field .custom-dropdown.no-arrow .Select-loading-zone {
    padding-right: 12px; }
  .dropdown-field .custom-dropdown.no-frame .Select-control {
    border: none !important;
    background: none !important; }
    .dropdown-field .custom-dropdown.no-frame .Select-control:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background-color: #da2631; }
    .dropdown-field .custom-dropdown.no-frame .Select-control:after {
      left: 16px;
      right: 16px; }
  .dropdown-field .custom-dropdown.no-frame .Select.is-open .Select-control:after {
    left: 0;
    right: 0; }
  .dropdown-field .custom-dropdown .Select-menu-outer {
    margin-top: 0;
    border-top: none;
    overflow: hidden;
    animation-name: dropdown-menu-appear;
    animation-duration: .5s; }

@keyframes dropdown-menu-appear {
  0% {
    max-height: 0; }
  100% {
    max-height: 200px; } }
  .dropdown-field .custom-dropdown.large .Select-control,
  .dropdown-field .custom-dropdown.large .Select-input {
    height: 48px; }
  .dropdown-field .custom-dropdown.large .Select-placeholder,
  .dropdown-field .custom-dropdown.large .Select > .Select-control .Select-value {
    line-height: 48px; }
  .dropdown-field .custom-dropdown.large .Select-option {
    padding-top: 14.5px;
    padding-bottom: 14.5px; }
  .dropdown-field .custom-dropdown.large .Select .icon {
    line-height: 48px; }
  .dropdown-field .custom-dropdown.medium .Select-control,
  .dropdown-field .custom-dropdown.medium .Select-input {
    height: 36px; }
  .dropdown-field .custom-dropdown.medium .Select-placeholder,
  .dropdown-field .custom-dropdown.medium .Select > .Select-control .Select-value {
    line-height: 36px; }
  .dropdown-field .custom-dropdown.medium .Select-option {
    padding-top: 8.5px;
    padding-bottom: 8.5px; }
  .dropdown-field .custom-dropdown.medium .Select .icon {
    line-height: 36px; }
  .dropdown-field .custom-dropdown.small .Select-control,
  .dropdown-field .custom-dropdown.small .Select-input {
    height: 28px; }
  .dropdown-field .custom-dropdown.small .Select-placeholder,
  .dropdown-field .custom-dropdown.small .Select > .Select-control .Select-value {
    line-height: 28px; }
  .dropdown-field .custom-dropdown.small .Select-option {
    padding-top: 4.5px;
    padding-bottom: 4.5px; }
  .dropdown-field .custom-dropdown.small .Select .icon {
    line-height: 28px; }

.dropdown-field [data-whatinput='mouse'] .custom-dropdown .Select.is-focused, .dropdown-field [data-whatinput='mouse'] .custom-dropdown .Select.is-pseudo-focused {
  outline: none !important; }

.dropdown-field.autofilled .custom-dropdown .Select-control {
  border-color: #3584b2 !important; }

.dropdown-field.has-error .custom-dropdown .Select-control {
  border-color: #da2631 !important; }

.dropdown-field.has-warning .custom-dropdown .Select-control {
  border-color: #f8961d !important; }

.dropdown-field.dark .custom-dropdown .Select-control {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: rgba(226, 224, 224, 0.8); }

.dropdown-field.dark .custom-dropdown .Select-input > input {
  color: white; }

.dropdown-field.dark .custom-dropdown .Select.is-focused > .Select-control, .dropdown-field.dark .custom-dropdown .Select.is-pseudo-focused > .Select-control {
  box-shadow: inset 0 0 0px 1px white;
  border-color: white; }

.dropdown-field.dark .custom-dropdown .Select .Select-clear-zone {
  color: #e2e0e0; }

.dropdown-field.dark .custom-dropdown .Select > .Select-control .Select-value, .dropdown-field.dark .custom-dropdown .Select > .Select-control .Select-value .Select-value-label {
  color: white !important; }

.dropdown-field.dark .custom-dropdown .Select-placeholder {
  color: rgba(65, 65, 66, 0.7); }
