.field {
  text-align: left;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column; }
  .field .field-inner {
    min-width: 260px; }
  .field.small .field-inner {
    min-width: 0;
    width: 160px; }
  .field.horizontal {
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 0; }
    .field.horizontal > label {
      margin-right: 12px; }
    .field.horizontal .field-inner {
      flex-basis: 0;
      flex-grow: 1;
      min-width: 0; }
  .field.last {
    margin-bottom: 0 !important; }
  .field label {
    font-family: "Source Sans Pro", sans-serif;
    text-transform: none;
    font-weight: 400;
    font-size: 1.6rem;
    letter-spacing: .03em;
    line-height: 2.6rem;
    margin-top: 0.43333rem;
    margin-bottom: 0.86667rem;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: .6rem;
    color: #414142; }
  .field.dark label {
    color: #e2e0e0; }
  .field .form-control {
    width: 100%;
    font-size: 18px;
    padding: 0 18px;
    font-weight: 300;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    outline: none !important;
    appearance: none; }
    .field .form-control::placeholder {
      color: rgba(65, 65, 66, 0.5); }
    .field .form-control::-moz-placeholder {
      opacity: 1; }
    .field .form-control[readonly] {
      background-color: rgba(226, 224, 224, 0.5) !important; }
  .field.dark .form-control::placeholder {
    color: rgba(65, 65, 66, 0.7); }
  .field .form-group-line {
    display: flex;
    align-items: stretch;
    position: relative; }
    .field .form-group-line > .form-control {
      height: auto;
      min-height: 50px; }
    .field .form-group-line > span {
      display: inline-flex; }
    .field .form-group-line .form-group-line-button .btn {
      margin-top: 0;
      margin-bottom: 0; }
  .field .form-group-children {
    position: relative;
    z-index: 2; }
    .field .form-group-children::after {
      display: block;
      clear: both;
      content: ""; }
    .field .form-group-children .help-block {
      margin: 0;
      padding-top: 5px;
      font-size: 1.3rem; }
  .field .form-control,
  .field .input-group .input-group-addon {
    border-color: #e2e0e0 !important;
    background-color: white !important;
    color: #262626 !important; }
  .field .form-control:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
  .field.dark .form-control,
  .field.dark .input-group .input-group-addon {
    border-color: rgba(226, 224, 224, 0.8) !important;
    background-color: rgba(255, 255, 255, 0.5) !important;
    color: white !important; }
  .field.dark .form-control:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
  .field.focused .form-control,
  .field.focused .input-group .input-group-addon {
    border-color: #262626 !important; }
  .field.focused .form-control:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
  .field.focused.dark .form-control,
  .field.focused.dark .input-group .input-group-addon {
    border-color: white !important; }
  .field.focused.dark .form-control:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
  .field.focused.dark .form-control {
    box-shadow: inset 0 0 0px 1px white; }
  .field.autofilled .form-control,
  .field.autofilled .input-group .input-group-addon {
    border-color: #3584b2 !important; }
  .field.autofilled .form-control:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
  .field.autofilled .input-group .input-group-addon {
    color: #3584b2 !important; }
  .field.autofilled .help-block {
    color: #3584b2; }
  .field.has-error .form-control,
  .field.has-error .input-group .input-group-addon {
    border-color: #da2631 !important; }
  .field.has-error .form-control:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
  .field.has-error .input-group .input-group-addon {
    color: #da2631 !important; }
  .field.has-error .help-block {
    color: #da2631; }
  .field.has-warning .form-control,
  .field.has-warning .input-group .input-group-addon {
    border-color: #f8961d !important; }
  .field.has-warning .form-control:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
  .field.has-warning .input-group .input-group-addon {
    color: #f8961d !important; }
  .field.has-warning .help-block {
    color: #f8961d; }
